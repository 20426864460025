import React from "react";
import "./footer.scss";
import logo from "../../asset/logo.webp";

const footer = () => {
  return (
    <div className="logo">
      <div className="container-footer">
        <div className="container-atas">
          <div className="container-logo">
            <img
              className="gambar-logo"
              src={logo}
              alt="logo-citraland-surabaya"
            />
            <small>Powered by : Linktown</small>
          </div>

          <div className="container-deskripsi">
            <div className="alamat">
              <h2>Marketing Gallery</h2>
              <h1>Navapark BSD City</h1>
              <p>Lengkong Kulon, Pagedangan, Tangerang Regency, Banten 15331</p>
            </div>
            <div className="contact">Contact us : +62812806111 </div>
            <a href="/privacy-policy" className="privasi">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
      <div className="container-bawah"></div>
    </div>
  );
};

export default footer;
