import React from "react";
import Newlaunchc from "../../../../component/newlaunch/newlaunchc";
import data from "../../../../component/promo/promocard.json";
import hsimages from "../../../../asset/hardsell/hsimages";
import "./newlaunch.scss";
import belovahs from "../../../../asset/hardsell/belovahs.webp";
import belovalogo from "../../../../asset/logo/belovalogo.webp";
import luxelogo from "../../../../asset/logo/luxelogo.webp";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import prime from "../../../../asset/logo/primeslogo.webp";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import tresorhs from "../../../../asset/hardsell/tresorhs.webp";
import tresor from "../../../../asset/logo/tresor.webp";
import { HashLink } from "react-router-hash-link";
import astera from "../../../../asset/hardsell/Newlaunch Desk.webp";
import asteramob from "../../../../asset/hardsell/Newlaunch Mob.webp";
const newlaunch = () => {
  const waasterra = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281319499502&text=Halo%20Reza,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Ruko%20https://marketingbsdcity.com/%20NewLaunching(Asterra)&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const wabelova = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281319499502&text=Halo%20Reza,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://marketingbsdcity.com/%20NewLaunching(adoraluxe)(belova)&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const waprime = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281319499502&text=Halo%20Reza,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://marketingbsdcity.com/%20Tresor&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const watresor = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281319499502&text=Halo%20Reza,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://marketingbsdcity.com/%20Tresor&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="newlaunch">
      <div className="container-newlaunch">
        <div className="coming-soon">
          <div id="newlaunch-asterra" className="header-newlaunch">
            NEW LAUNCHING <br />
            ASTERRA BUSINESS PARK
          </div>
          <img src={asteramob} alt="coming soon" className="mobile-view" />
          <img src={astera} alt="coming soon " className="desktop-view" />
          <button onClick={waasterra} className="whatsapp">
            <FontAwesomeIcon icon={faWhatsapp} /> Get More Info
          </button>
        </div>

        <div className="header-newlaunch">NEW LAUNCHING</div>
        <div id="wynyard" className="newlaunchcard">
          {data.Wynyard.map((promo, index) => {
            // Determine the logo dynamically
            const fotopromo = hsimages[promo.imagehs];
            return (
              <Newlaunchc
                key={index}
                imagehs={fotopromo} // Dynamically import logo images
                titlepromo={promo.titlepromo}
                details={promo.details}
                whatsappLink={promo.whatsappLink}
                pageLink={promo.pageLink} // Pass the WhatsApp link
              />
            );
          })}
        </div>
        <div className="belova">
          <div className="header-belova">
            <div className="judulb">Terravia Adora</div>
            <div className="subjudulb">
              Adora Primes/Adora Luxe/Belova Classic
            </div>
          </div>
          <div className="container-cardb">
            <div className="hardsell-b">
              {" "}
              <img src={belovahs} alt="hardsell" />
            </div>
            <div className="card">
              <a className="overlay" href="/terravia">
                <div className="belova">
                  <div className="atas">
                    <div className="promos">TANPA DP & FREE BIAYA KPR</div>
                    <div className="detail">
                      <div className="start">Start From</div>
                      <div className="angka">9</div>
                      <div className="ket">Juta/Bulan</div>
                    </div>
                  </div>
                  <div className="bawah">
                    <div className="logos1">
                      <img src={luxelogo} alt="luxelogo" />
                    </div>
                    <div className="logos2">
                      <img src={belovalogo} alt="belovalogo" />
                    </div>
                  </div>
                </div>
              </a>
              <div className="buttonwa1">
                <button onClick={wabelova} className="whatsapp">
                  {" "}
                  <FontAwesomeIcon icon={faWhatsapp} /> Get More Info
                </button>
              </div>
              <a className="overlay" href="/terravia">
                <div className="belova">
                  <div className="atas">
                    <div className="promos">TANPA DP & FREE BIAYA KPR</div>
                    <div className="detail">
                      <div className="start">Start From</div>
                      <div className="angka">13</div>
                      <div className="ket">Juta/Bulan</div>
                    </div>
                  </div>
                  <div className="bawah">
                    <div className="logos3">
                      <img src={prime} alt="adoraprime" />
                    </div>
                  </div>
                </div>
              </a>
              <div className="button">
                <div className="buttonwa2">
                  <button onClick={waprime} className="whatsapp">
                    {" "}
                    <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                  </button>
                </div>
                <div className="buttonwa3">
                  <HashLink className="link" smooth to="/terravia">
                    <button className="page">
                      {" "}
                      Detail Product <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tresor">
          <div className="header-tresor">
            <div className="judult">TRESOR</div>
            <div className="subjudult">(Excuisite Majesty)</div>
          </div>
          <div className="container-cardt">
            <div className="hardsell-t">
              <img src={tresorhs} alt="hardsell" />
            </div>
            <div className="card">
              <a className="overlay" href="/tresor">
                <div className="tresor1">
                  <div className="atas">
                    <div className="promos">TANPA DP & FREE FURNISHED</div>
                    <div className="detail">
                      <div className="start">Discount UP TO</div>
                      <div className="angka">2,6</div>
                      <div className="ket">Miliar</div>
                    </div>
                  </div>
                  <div className="bawah">
                    <div className="logos3">
                      <img src={tresor} alt="adoraprime" />
                    </div>
                  </div>
                </div>
              </a>
              <div className="button">
                <div className="buttonwa2">
                  <button onClick={watresor} className="whatsapp">
                    <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
                  </button>
                </div>
                <div className="buttonwa3">
                  <HashLink className="link" smooth to="/tresor">
                    <button className="page">
                      Detail Product <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default newlaunch;
