import { faBuilding, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { HashLink } from "react-router-hash-link";
import "./commercial.scss";
import dataruko from "../../../component/cardruko/dataruko.json";
import Cardruko from "../../../component/cardruko/cardruko";
import Akasa from "../../../asset/Ruko/Akasa/index";
import West from "../../../asset/Ruko/Westvillage/index";
import Capital from "../../../asset/Ruko/Capital/index";
import Cascade from "../../../asset/Ruko/Cascade/index";
import Delrey from "../../../asset/Ruko/Delrey/index";
import Latino from "../../../asset/Ruko/Latino/index";
import North from "../../../asset/Ruko/North/index";
import Theloop from "../../../asset/Ruko/Theloop/index";
import RukoLogo from "../../../asset/RukoLogo/index";
import Vanya from "../../../asset/Ruko/Vanya/index";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderruko from "../../../asset/sliderruko/sliderruko";
import Promoc from "../../../component/promo/promoc";
import hsimages from "../../../asset/hardsell/hsimages";
import prmo from "../../../component/promo/promocard.json";
import ScrollToHashElement from "./ScrollToHashElement";
import xlane from "../../../asset/Ruko/xlane/xlane";
const commercialMap = {
  Akasa,
  West,
  Capital,
  Cascade,
  Delrey,
  Latino,
  North,
  Theloop,
  Vanya,
  xlane,
};
const commercial = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768,
        lazyload: true, // Adjust breakpoint for mobile devices
        settings: {
          slidesToShow: 1, // Show only 1 slide on mobile (optional)
          swipeToSlide: true,

          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="commercial">
      <ScrollToHashElement />
      <div className="container-commercial">
        <div className="containerheader-commercial">
          <div className="navigasi">
            <HashLink className="link" smooth to="/">
              <div className="line">
                <FontAwesomeIcon icon={faHouse} />
                &nbsp;&nbsp;Home
              </div>
            </HashLink>
            <HashLink className="link" smooth to="/residentials">
              <div className="line">
                <FontAwesomeIcon icon={faBuilding} />
                &nbsp;&nbsp;Residential
              </div>
            </HashLink>
          </div>
          <div className="h1">Pilihan Ruko BSD City</div>
        </div>
        <div className="dividera"></div>
        <div className="a">
          <div id="xlane" className="newlaunching">
            {prmo.xlane.map((promo, index) => {
              // Determine the logo dynamically
              const fotopromo = hsimages[promo.imagehs];
              return (
                <Promoc
                  key={index}
                  imagehs={fotopromo} // Dynamically import logo images
                  titlepromo={promo.titlepromo}
                  details={promo.details}
                  whatsappLink={promo.whatsappLink}
                  quote={promo.quote} // Pass the WhatsApp link
                />
              );
            })}
          </div>
          <div className="carousel-produk">
            <Slider {...settings}>
              {sliderruko.map((tanamas, index) => (
                <img className="devb" key={index} src={tanamas} alt={"dev"} />
              ))}
            </Slider>
          </div>
          <div className="container-cardproduk">
            {dataruko.commercial.map((card, index) => {
              // Determine the logo dynamically
              const myzaLogo = RukoLogo[card.logo];

              return (
                <Cardruko
                  key={index}
                  carousel={commercialMap[card.imagesKey]}
                  logo={myzaLogo} // Dynamically import logo images
                  price={card.price}
                  subprice={card.subprice}
                  details={card.details}
                  whatsappLink={card.whatsappLink}
                  iconcard={faLayerGroup} // Pass the WhatsApp link
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default commercial;
