import Akasalogo from "./Akasalogo.png";
import Westvillagelogo from ".//Westvillagelogo.webp";
import Capitalogo from "./Capitallogo.png";
import Cascadelogo from "./Cascadelogo.png";
import Delreylogo from "./Delreylogo.png";
import Latinologo from "./Latinoslogo.png";
import Northlogo from "./Northlogo.png";
import Thelooplogo from "./Thelooplogo.png";
import Vanyalogo from "./Vanya.webp";
import Xlanelogo from "./xlanelogo.png";

const RukoLogo = {
  Akasalogo,
  Westvillagelogo,
  Capitalogo,
  Cascadelogo,
  Delreylogo,
  Latinologo,
  Northlogo,
  Thelooplogo,
  Vanyalogo,
  Xlanelogo,
};

export default RukoLogo;
